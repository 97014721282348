<template>
    <b-overlay :show="showLoader">
        <b-card class="my-1 d-flex align-center" :title="$t('cash.cashes')">

            <basic-table :columns="columns" :data="cash_registers" v-slot="props" v-if="loaded">
                <span v-if="props.column.displayType === 4" class="d-flex justify-content-center">
                    <span v-if="props.row[props.column.field]">
                        {{props.row[props.column.field]}}
                    </span>
                    <span v-else>
                        /
                    </span>
                </span>
                <span v-else-if="props.column.displayType === 5" class="d-flex justify-content-center">
                    <b-badge variant="success" v-if="props.row[props.column.field]">
                        {{$t('general.yes')}}
                    </b-badge>
                    <b-badge variant="danger" v-else>
                        {{$t('general.no')}}
                    </b-badge>
                </span>
                <span v-else-if="props.column.displayType === 6" class="d-flex justify-content-center">
                    <b-badge variant="primary" v-if="props.row[props.column.field] === 0">
                        {{$t('general.no_data')}}
                    </b-badge>
                    <b-badge variant="danger" v-else-if="props.row[props.column.field] === 1">
                        {{$t('general.no')}}
                    </b-badge>
                    <b-badge variant="success" v-else-if="props.row[props.column.field] === 2">
                        {{$t('general.yes')}}
                    </b-badge>
                </span>
                <span v-else-if="props.column.displayType === 7" class="d-flex justify-content-center">
                    <b-badge variant="warning" v-if="props.row[props.column.field]">
                        {{$t('general.yes')}}
                    </b-badge>
                    <b-badge variant="success" v-else>
                        {{$t('general.no')}}
                    </b-badge>
                </span>
                <span v-else-if="props.column.displayType === 8" class="d-flex justify-content-center">
                    <b-button :variant="props.row.disabled? 'success': 'danger'" class="m-0" style="padding: 5px 5px;" @click="changeStatusOfCashRegister(props.row.disabled, props.row.id, props.row.client_id)">
                        <span v-if="props.row.disabled">
                            {{$t('general.enable')}}
                        </span>
                        <span v-else>
                            {{$t('general.disable')}}
                        </span>
                    </b-button>
                </span>
                <span v-else-if="props.column.displayType === 9" class="d-flex justify-content-center">
                    <b-badge variant="secondary" v-if="props.row.device_type === CashRegisterType.Unknown">
                        Ni podatka
                    </b-badge>
                    <b-badge style="background: #1ab7ea;" v-else-if="props.row.device_type === CashRegisterType.Windows">
                        Windows
                    </b-badge>
                    <b-badge variant="success" v-else-if="props.row.device_type === CashRegisterType.MobileAndroid">
                        Android Mobile
                    </b-badge>
                    <b-badge  style="background: #52bb52;" v-else-if="props.row.device_type === CashRegisterType.DesktopAndroid">
                        Android Desktop
                    </b-badge>
                </span>
                <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>
            </basic-table>

        </b-card>

    </b-overlay>
</template>

<script>
    import BasicTable from '@/views/components/BasicTable'
    import {BCard, BButton, BOverlay, BBadge} from 'bootstrap-vue'
    import {captureException} from '@sentry/vue'
    import {CashRegisterType} from '@/libs/enums/CashRegisters'

    export default {
        computed: {
            CashRegisterType() {
                return CashRegisterType
            }
        },
        components: {BasicTable, BButton, BCard, BOverlay, BBadge},
        data() {
            return {
                loaded: false,
                cash_registers: [],
                versions: [],
                clients: [],
                businessUnits: [],
                columns: [
                    {
                        label: this.$t('cash.cash_mark'),
                        field: 'cash_register_id',
                        displayType: 0,
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: this.$t('cash.active'),
                        field: 'status',
                        displayType: 6,
                        filterOptions: {
                            enabled: true,
                            filterDropdownItems: [this.$t('general.yes'), this.$t('general.no'), this.$t('general.no_data')],
                            filterFn: this.statusFilter
                        }
                    },
                    {
                        label: this.$t('general.disabled'),
                        field: 'disabled',
                        displayType: 7,
                        filterOptions: {
                            enabled: true,
                            filterDropdownItems: [this.$t('general.yes'), this.$t('general.no')],
                            filterFn: this.boolFilter
                        },
                        sortable: false
                    },
                    {
                        label: this.$t('general.device'),
                        field: 'device_type',
                        displayType: 9,
                        filterOptions: {
                            enabled: true,
                            filterDropdownItems:  [
                                {
                                    value: CashRegisterType.Windows,
                                    text: 'Windows'
                                },
                                {
                                    value: CashRegisterType.MobileAndroid,
                                    text: 'Android Mobile'
                                },
                                {
                                    value: CashRegisterType.DesktopAndroid,
                                    text: 'Android Desktop'
                                },
                                {
                                    value: CashRegisterType.Unknown,
                                    text: 'Ni podatka'
                                }
                            ]
                        },
                        sortable: false
                    },
                    {
                        label: this.$t('client.client'),
                        field: 'client',
                        displayType: 1,  
                        filterOptions: {
                            enabled: true
                        }
                    },
                    {
                        label: this.$t('cash.business_unit'),
                        field: 'business_unit',
                        displayType: 2,
                        filterOptions: {
                            enabled: true
                        },
                        sortable: false
                    },
                    {
                        label: this.$t('client.tier'),
                        field: 'latest_version',
                        displayType: 4,
                        filterOptions: {
                            enabled: true,
                            filterDropdownItems: this.versions
                        },
                        sortable: false
                    },
                    {
                        label: this.$t('cash.sync'),
                        field: 'enable_sync',
                        displayType: 5,
                        filterOptions: {
                            enabled: true,
                            filterDropdownItems: ['Da', 'Ne'],
                            filterFn: this.boolFilter
                        },
                        sortable: false
                    },
                    {
                        label: this.$t('cash.active_in_the_last_week'),
                        field: 'active_in_last_week',
                        displayType: 5,
                        filterOptions: {
                            enabled: true,
                            filterDropdownItems: [this.$t('general.yes'), this.$t('general.no')],
                            filterFn: this.boolFilter
                        },
                        sortable: false
                    },
                    {
                        label: this.$t('table.options'),
                        field: 'enabled',
                        displayType: 8,
                        sortable: false
                    }
                ],
                showLoader: false
            }
        },
        methods: {
            async changeStatusOfCashRegister(disabled, cashRegisterId, clientId) {
                let status = ''

                if (disabled) {
                    status = 'enable'
                } else {
                    status = 'disable'
                }

                try {
                    await this.$http.post(`/api/reseller/v1/cash_registers/status/${  status  }/${  clientId  }/${  cashRegisterId}`)

                    const index = this.cash_registers.findIndex(ele => ele.id === cashRegisterId)

                    if (index >= 0) {
                        this.cash_registers[index].disabled = !disabled
                    }

                    if (disabled) {
                        this.$printSuccess(this.$t('cash.cash_has_been_enabled_successfully'))
                    } else {
                        this.$printSuccess(this.$t('cash.cash_has_been_disabled_successfully'))
                    }
                } catch (error) {
                    captureException(error)
                    this.$printError(this.$t('cash.change_error_occurred'))
                }

            },
            boolFilter(data, filterString) {
                if (filterString === this.$t('general.yes') && data) {
                    return true
                } else if (filterString === this.$t('general.no') && !data) {
                    return true
                }

                return false
            },
            statusFilter(data, filterString) {
                if (filterString === this.$t('general.yes') && data === 2) {
                    return true
                } else if (filterString === this.$t('general.no') && data === 1) {
                    return true
                } else if (filterString === this.$t('general.no_data') && data === 0) {
                    return true
                }

                return false
            },

            async loadData() {
                this.showLoader = true

                try {
                    const response = await this.$http.get('/api/reseller/v1/cash_registers/all_cash_registers')
                    this.cash_registers = response.data ?? []

                    const versionMap = {}
                    const clientMap = {}
                    const businessUnitMap = {}
                    for (const cashRegister of this.cash_registers) {
                        if (!versionMap[cashRegister.latest_version]) {
                            if (cashRegister.latest_version === '') {
                                this.versions.push(this.$t('cash.no_version'))
                            } else {
                                this.versions.push(cashRegister.latest_version)
                            }
                        }

                        if (!clientMap[cashRegister.client]) {
                            this.clients.push(cashRegister.client)
                        }

                        if (!businessUnitMap[cashRegister.business_unit]) {
                            this.businessUnits.push(cashRegister.business_unit)
                        }

                        clientMap[cashRegister.client] = true
                        businessUnitMap[cashRegister.business_unit] = true
                        versionMap[cashRegister.latest_version] = true
                    }

                    const index = this.columns.findIndex(ele => ele.field === 'latest_version')
                    this.columns[index].filterOptions.filterDropdownItems = this.versions

                    const indexClient = this.columns.findIndex(ele => ele.field === 'client')
                    this.columns[indexClient].filterOptions.filterDropdownItems = this.clients

                    const indexBusinessUnit = this.columns.findIndex(ele => ele.field === 'business_unit')
                    this.columns[indexBusinessUnit].filterOptions.filterDropdownItems = this.businessUnits
                } catch (error) {
                    captureException(error)
                    this.$printError(this.$t('error.on_load_data'))
                } finally {
                    this.showLoader = false
                    this.loaded = true
                }

            }
        },
        async mounted() {
            await this.loadData()
        }
    }
</script>

<style scoped>

</style>
